<template>
	<section class="change-password center">
		<div style="width:100%; max-width:400px;">
			<quote-title label="修改密碼" />
			<br>
			<br>
			<el-form ref="ruleForm" :model="ruleForm">
				<el-form-item label="密碼" prop="password" :rules="rule(/[a-zA-Z0-9]{8,}/, '８碼以上英文字母與半形數字')">
					<el-input v-model="ruleForm.password" placeholder="密碼" show-password class="round" />
				</el-form-item>
				<el-form-item label="請再次輸入密碼" prop="repeat_password" :rules="validatePassword(ruleForm)">
					<el-input v-model="ruleForm.repeat_password" placeholder="請再次輸入密碼" show-password class="round" />
				</el-form-item>
				<el-form-item>
					<br>
					<div class="center">
						<el-button type="primary" @click="handleChangePassword()">確認修改</el-button>
					</div>
				</el-form-item>
			</el-form>
		</div>
	</section>
</template>

<script>
import { mapActions } from "vuex";
export default {
	data() {
		return {
			ruleForm: new Object,
			rule: function (regex, errMsg) {
				return [
					{ required: true, message: "必填" },
					{
						validator: (rule, value, callback) => {
							if (!regex || (regex && regex.test(value))) {
								callback();
							} else {
								callback(new Error(errMsg || "格式錯誤"));
							}
						},
						trigger: "blur"
					}
				];
			}
		}
	},
	methods: {
		...mapActions("auth", ["_changePassword"]),
		validatePassword({ password, repeat_password }) {	// 驗證重複密碼
			return [
				{ required: true, message: "必填" },
				{
					validator: (rule, value, callback) => {
						if (password === repeat_password) callback();
						else callback(new Error("兩次密碼不一樣"));
					}
				}
			]
		},
		handleChangePassword() {
			this.$refs["ruleForm"].validate(async (valid, object) => {
				if (valid) {
					await this._changePassword(this.ruleForm);
					this.$message.success({ message: "密碼更新成功" });
					this.$router.push("/profile");
				}
			});
		},
	}
}
</script>

<style lang="scss" scoped>
.change-password {
	padding: 50px 5%;
}
</style>